:root {
    --main-bg-color: rgb(20, 14, 14);
    --secodary-bg-color: rgb(27, 23, 23);
    --navbar-bg-color: transparent;

    --sixth-accent-bg-color: rgb(162, 3, 241);
    --seventh-accent-bg-color: rgb(238, 198, 129);
    --eight-accent-bg-color: rgb(106, 243, 174);
    --touchable-bg-color: rgb(151, 58, 62);


    --accent-bg-color: #FF652F;
    --secondary-accent-bg-color: #FFE400;
    --third-accent-bg-color: #14A76C;
    --fourth-accent-bg-color: rgba(132, 153, 0);
    --fifth-accent-bg-color: rgba(205, 74, 46); 

    /*--accent-bg-color: rgba(230, 240, 220); 
    --secondary-accent-bg-color: rgba(180, 200, 201); 
    --third-accent-bg-color: rgba(140, 155, 128); 
    --fourth-accent-bg-color: rgba(110, 105, 100);
    --fifth-accent-bg-color: rgba(80, 70, 75); */


    --main-bg-color: rgb(10, 9, 9);
    --secodary-bg-color: rgb(44, 3, 3);
    --navbar-bg-color: transparent;


    /*--accent-bg-color: rgba(107, 112, 196);
    --secondary-accent-bg-color: rgba(196, 140, 209);
    --third-accent-bg-color: rgba(40, 160, 153);
    --fourth-accent-bg-color: rgba(132w, 153, 0);
    --fifth-accent-bg-color: rgba(205, 74, 46);*/
    /* --sixth-accent-bg-color: rgba(219, 51, 46, 0.5);
    --seventh-accent-bg-color: rgba(212, 51, 135, 0.5);
    --eight-accent-bg-color: rgba(212, 51, 135 , 0.5);
    --touchable-bg-color: rgba(245,74,83, 0.5);
    --accent-bg-color: rgba(148, 0, 0211 , 0.5);
    --secondary-accent-bg-color: rgba(75, 0, 130 , 0.5);
    --third-accent-bg-color: rgba(0,0,255 0.5);
    --fourth-accent-bg-color: rgba(255,255,0 , 0.5);
    --fifth-accent-bg-color: rgba(255,127,0. 0.5); */


    /* --accent-bg-color: #581845;
    --secondary-accent-bg-color: #900C3F;
    --third-accent-bg-color: #C70039;
    --fourth-accent-bg-color: #FF5733;
    --fifth-accent-bg-color: #FFC30F; */

/*
     --accent-bg-color: #36C5F0;
    --secondary-accent-bg-color: #E01E5A;
    --third-accent-bg-color: #ECB33E;
    --fourth-accent-bg-color: #2EB67D;
    --fifth-accent-bg-color: rgb(219, 212, 215);  
    --sixth-accent-bg-color: rgba(219, 51, 46);
    --seventh-accent-bg-color: rgba(212, 51, 135);
    --eight-accent-bg-color: rgba(212, 51, 135);*/


    --small-margin: 25px;
    --big-margin: 50px;
  }
  
  .background_color_accent {
    background-color: var(--accent-bg-color);
  }

  .background_color_accent_sec {
    background-color: var(--secondary-accent-bg-color);
  }

  .background_color_accent_third {
    background-color: var(--third-accent-bg-color);
  }

  .background_color_accent_fourth {
    background-color: var(--fourth-accent-bg-color);
  }

  .background_color_accent_fifth {
    background-color: var(--fifth-accent-bg-color);
  }

  
.padding-left-20 {
  padding-left: 10px;
}
  .uniform_small_margin {
    margin: var(--small-margin);
  }
  
  .uniform_big_margin {
    margin: var(--big-margin);
  }

  .bottom_big_margin {
    margin-bottom: var(--big-margin);
  }

  .top_big_margin {
    margin-top: var(--big-margin);
  }

  .left_big_margin {
    margin-left: var(--big-margin);
  }


  .bottom_small_margin {
    margin-bottom: var(--small-margin);
  }

  .text_color_accent {
    color: var(--accent-bg-color);
  }

  .text_color_black {
    color: black;
  }

  .text_color_accent:hover {
    color: var(--accent-bg-color);
  }

  .text_color_main:hover {
    color: var(--main-bg-color);
  }

  .text_decoration {
      text-decoration: underline;
      text-decoration-color: var(--accent-bg-color);
  }

  .accent_bottom_border {
    border-bottom: 1px solid var(--accent-bg-color);
}

.accent_right_border {
  border-right: 1x solid var(--accent-bg-color);
}

.accent_left_border {
  border-left: 1px solid var(--accent-bg-color);
}

.accent_top_border {
    border-top: 1px solid var(--accent-bg-color);
}


.img_color_accent {
        -webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
        filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
  }

  .img_color_black {
    -webkit-filter: invert(100%) grayscale(100%) brightness(70%) contrast(4);
    filter: invert(100%) grayscale(100%) brightness(70%) contrast(4);
}

.fill { 
  min-height: 100%;
  height: 100%;
}

section:nth-child(even) {
  background-color: #1c1c1c;
  color: #dbdbdb;
  }
  
  /* img { */
    /* verical-align: middle;
    :hover {
      cursor: pointer;
      .red {
        -webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
        filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
      }
      .blue {
        -webkit-filter: grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.8);
        filter: grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.8);
      }
      .green {
        -webkit-filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
        filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
      }
      .yellow {
        -webkit-filter: grayscale(100%) brightness(120%) sepia(90%) hue-rotate(5deg) saturate(500%) contrast(0.7);
        filter: grayscale(100%) brightness(120%) sepia(90%) hue-rotate(5deg) saturate(500%) contrast(0.7);
      }
      .grey {
        -webkit-filter: grayscale(100%) brightness(110%) contrast(0.9);
        filter: grayscale(100%) brightness(110%) contrast(0.9);
      }
      .black {
        -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
        filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
      }
    }
  } */