
/* navbar */
.fixed-top-2 {
    height: 45px;
    border-bottom: 0px solid black;
}

.bottom_border_class {
  border-bottom: 1px solid rgba(255, 255, 255, 0.01);
  /*z-index: 8888;
  top: 50px;
  margin-top: -50px;*/
}


/* navbar */
.navbar-dark {
    background-color: var(--navbar-bg-color);
    /*border-color: var(--accent-bg-color);*/
}
/* navbar */
.navbar-black {
    /*transition: 1.1s ease-out;*/
    background-color: rgba(0, 0, 0, 0.9);
}
/* navbar */
.navbar-transparent {
    transition: 1.1s ease-out;
    background-color: rgba(0, 0, 0, 0);
}
/* Title */
.navbar-dark .navbar-brand {
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    transition: 0.5s ease-out;
    background-color: var(--accent-bg-color);
}

.navbar-dark .dropdown-toggle:hover,
.navbar-dark .dropdown-toggle:focus {
    transition: 0.5s ease-out;
    background-color: var(--accent-bg-color);
}


.navbar-dark .nav-link:hover,
.navbar-dark .nav-link:focus {
    color: var(--accent-bg-color);
}

.nav > li > a:hover, .nav > li > a:focus {
    color: black;
    transition: 0.5s ease-out;
    /*background-color: var(--accent-bg-color);*/
    color: black;
}

.id-item > li > a:hover, .id-item > li > a:focus {
    transition: 0.5s ease-out;
    background-color: var(--accent-bg-color);
    color: black;
}



.id-item > li > nav-link:hover , .id-item > li > nav-link:hover {
    color: black;
}

.hover_color_red {
}
/* Link */
.navbar-dark .navbar-nav > li > a {
}
.navbar-dark .navbar-nav > li > a:hover,
.navbar-dark .navbar-nav > li > a:focus {

}


.navbar-dark .navbar-nav > .active > a,
.navbar-dark .navbar-nav > .active > a:hover,
.navbar-dark .navbar-nav > .active > a:focus {
}
.navbar-dark .navbar-nav > .open > a,
.navbar-dark .navbar-nav > .open > a:hover,
.navbar-dark .navbar-nav > .open > a:focus {
    
}
/* Caret */
.navbar-dark .navbar-nav > .dropdown > a .caret {

}
.navbar-dark .navbar-nav > .dropdown > a:hover .caret,
.navbar-dark .navbar-nav > .dropdown > a:focus .caret {
}
.navbar-dark .navbar-nav > .open > a .caret,
.navbar-dark .navbar-nav > .open > a:hover .caret,
.navbar-dark .navbar-nav > .open > a:focus .caret {
 
}
/* Mobile version */
.navbar-dark .navbar-toggle {
}
.navbar-dark .navbar-toggle:hover,
.navbar-dark .navbar-toggle:focus {
}
.navbar-dark .navbar-toggle .icon-bar {
}
@media (max-width: 767px) {
    .navbar-dark .navbar-nav .open .dropdown-menu > li > a {
    }
    .navbar-dark .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-dark .navbar-nav .open .dropdown-menu > li > a:focus {
    }
}

.navbar-fixed-left {
    width: 140px;
    position: fixed;
    border-radius: 0;
    height: 100%;
  }
  
  .navbar-fixed-left .navbar-nav > li {
    float: none;  /* Cancel default li float: left */
    width: 139px;
  }
  
  .navbar-fixed-left + .container {
    padding-left: 160px;
  }
  
  /* On using dropdown menu (To right shift popuped) */
  .navbar-fixed-left .navbar-nav > li > .dropdown-menu {
    margin-top: -50px;
    margin-left: 140px;
  }


  /* Sidebar Styles */

#sidebar {
  z-index: 1000;
  position: fixed;
  right: 250px;
  width: 200px;
  margin-right: -250px;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#sidebar header {
  background-color: #263238;
  font-size: 20px;
  line-height: 52px;
  text-align: center;
}

#sidebar header a {
  color: #fff;
  display: block;
  text-decoration: none;
  transform: rotate(90deg);
}

#sidebar header a:hover {
  color: #fff;
}

#sidebar .nav{
  
}

#sidebar .nav a{
  background: none;
  border-bottom: 1px solid #455A64;
  color: #CFD8DC;
  font-size: 14px;
  padding: 16px 24px;
}

#sidebar .nav a:hover{
  background: none;
  color: #ECEFF1;
}

#sidebar .nav a i{
  margin-right: 16px;
}
