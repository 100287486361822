
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  font-style: normal; font-variant: normal; font-weight: 400; 
  font-size: 1.17em !important;
    /* IE */
    /*filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);*/
    /* Chrome, Safari */
    /*-webkit-filter: grayscale(0.8);*/
    /* Firefox */
    /*filter: grayscale(0.8);*/
}



h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .display-1, .display-2,.display-3,.display-4  {
  margin-bottom: 0.5rem;
  font-family: "Helvetica Neue";
  font-weight: 350;
  line-height: 1.2;
  color: inherit;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
h1 { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-size: 24px; font-style: normal; font-variant: normal; font-weight: 100; line-height: 26.4px; } 
h3 { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-size: 14px; font-style: normal; font-variant: normal; font-weight: 100; line-height: 15.4px; } 
p { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; ; font-style: normal; font-variant: normal; font-weight: 100; line-height: 20px; } 
blockquote { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-style: normal; font-variant: normal; font-weight: 100; line-height: 25px; } 
pre { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-style: normal; font-variant: normal; font-weight: 100; line-height: 18.5714px; }
a { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-style: normal; font-variant: normal; font-weight: 100; line-height: 18.5714px; }
body { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-style: normal; font-variant: normal; font-weight: 100; line-height: 18.5714px; }
*/

.main {
    /*background: #2d2d2d;*/
    border-color: #E7E7E7;
    color: #f1f1f1;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    margin: 0 0 0 0;
    right: 0px;
    left: 0px;
    top: 0;
    background: black;
}

/* Container holding the image and the text */
.container-main {
  position: relative;
  /*margin-bottom: 50px;*/
  /*transition: 0.4s cubic-bezier(1, -1, 0, 2);
  clip-path: circle(600px at center);*/
}

/* Bottom right text */
.text-block-main {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: black;
  opacity: 1;
  color: white;
  background:linear-gradient(to bottom,rgba(0, 0, 0, 1) ,rgba(0, 0, 0, 0.5) 30%);
  background-color:transparent; /*this your primary color*/
}




/* Bottom right text */
.text-block-main-right {
  width: 100%;
  height: 10%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0);
  opacity: 1;
  color: white;
  text-align: middle;
  vertical-align: middle;
}

.text-block-main-bottom {
  width: 75%;
  height: 12.5%;
  position: absolute;
  right: 12.5%;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 1);
  opacity: 1;
  color: white;
  text-align: middle;
  vertical-align: middle;
}

.display-44 {
  color: white;
}

.manual_link {
  color: var(--accent-bg-color);
}


.store-button {
  border-color: white;
  color: white;
  background-color: black;
  opacity: 1;

}

.store-button:hover {
  transition: 1s ease-out;
  background-color: var(--accent-bg-color);
  color: white;
  opacity: 1;

}

.list-group-item {
  background: black;;
}

.desc-text-left {
    /*height: 100%;*/
    width: 30%;
    text-align: left;
    float: left;
}


.desc-image-right {
  /*height: 100%;*/
   width: 70%;
    /*text-align: right;
    float: left;*/
    opacity: 0.9;
    transition: .5s ease;
}

.desc-image-right:hover {
  opacity: 1;
}

.desc-image-left {
    width: 70%;
    height: 100%;
    text-align: left;
    float: left;
    overflow: hidden;
    opacity: 0.9;
    transition: .5s ease;
}

.desc-image-left:hover {
  opacity: 1;
}


.desc-text-right {
    width: 30%;
    height: 100%;
    float: left;
}

.footer-heading {
  color: white;
}

.copyright-footer {
  color: white;
}

.bg-dark {
  background: black;
}

.ul_text_left {
  text-align: left;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.asdasd {
    width: 100%;
}

.desc_box_margin {
  /*margin-top: 25px;
  margin-bottom: 25px;*/
}

.footer_styles {
  background-color: var(--navbar-bg-color);
}

.footer_margin {
  margin-top: 25px;
}


.product_row {
    background-color: #C0C0C0;
}

.product_row_box {
  vertical-align: middle;
  text-decoration: none;
  background-color: var(--secodary-bg-color);
  color: white;
  box-shadow: -2px -2px 5px 4px #B0B0B0
}

.product_detail_box{
  transition: 1s ease-out;
  /*background-color: var(--accent-bg-color);*/
}

.product_detail_box:hover{
  transition: 1s ease-out;
  background-color: white;
  transform: scale(1.005);
}

.image_bottom {
  /*position: absolute;
  bottom: 0px;
  width: 100;
  text-align: center;
  width: 100%;
  background-color: var(--secondary-accent-bg-color);*/
  vertical-align:middle;
}

.product_row_box:hover{
  transition: 1s ease-out;
  background-color: white;
  transform: scale(1.01);
  
}

.product_row_box{
  transition: 1s ease-out;
  transform: scale(1);
}


.inner-product-box:hover {
  transition: 1s ease-out;
  /*transform: scale(1.15);*/
}

.inner-product-box {
  transition: 1s ease-out;
  transform: scale(1);
}

.product_row_box:hover a h4{
  transition: 2s ease-out;
  color: black;
}

.product_row_box:hover a .product_box_desc{
  transition: 2s ease-out;
  color: black;
}



.main_bar_margin {
  margin-top: 56px;
}

.sub_bar_margin {
  margin-top: 40px;
}

.font_color_main_background {
  color: var(--main-bg-color);
}

a {
  color: #ffffff ;
}
a:visited {
  color: #ffffff ;
}
a:hover, a:active, a:focus {
  color: #ffffff ;
}
a:hover {
  color: #ffffff ;
  text-decoration: none;
}


.flexy-header { height: 10vh; }
.hero-content { transition: all 3s ease, opacity 0.5s ease; opacity: 1; }
.flexy-header .hero-content { opacity: 0; height: 0; transition: all 3s ease, opacity 0.5s ease; }

.youtube {
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  overflow: hidden;
  transition: all 200ms ease-out;
  cursor: pointer;
}

.youtube .play {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAERklEQVR4nOWbTWhcVRTHb1IJVoxGtNCNdal2JYJReC6GWuO83PM/59yUS3FRFARdFlwYP1CfiojQWt36sRCUurRIdVFXIn41lAoVdRGrG1M01YpKrWjiYmaSl8ybZJL3cd+YA//NLObd3++eO8x79z5jSq5Gw+8kov0AP8vMR5l1BtBZQM4B8ks75wCdZdYZZj5qLZ4hov2Nht9Z9vhKKSIaB/gI4M4w62KeAO6Mte4lYOq20FxrlqqOibhHmeWbvNC9ZfDX1mLae391aN6limO/gwgvAPJbWeAZuSDingdwXTBw7/0IsyaA/Fkh+KqOkD+YNfHej1QKD+y7iVlOhgLvFqFfNJvNGyuBJ+KDAF8MDd0tgS8y64OlgSdJMsysL4cG7SOHkyQZLhTee7+d2R2rAVy/S+Jd7/32ouBHAP4gNNRGQyTHc/84NhqNywZp5rvjjnnvt21aABFeCQ+RLwAf2hQ8s7sv9OCLk6AHNgQvIrvbfzKCD76g/O6cu7lf/iER/aQGgy448pExZmhdegAPhR9sObFWH1gT3lp7DaA/5bkIgJhZPgsNmz02novj+KqeApj1ubwXWe4kdyeznAgNvTpE/HQmvKqOMeuFogTUVQSRno+iaLRLAJF7uIgL9O4ubgL8aWgB7S44mNX+35YpICUiAvS9sBLkq1WzT+NFffl6AuoiApi6NT37h6sWkBIRZGkQ8YtLgyji6e1mBYTqCEBPG2Naz+0BWQgtoGoRgCzEsd9hAN1X5BfnFZASUfrSAFQNsyZ1FJASUVpHiLinDJG8U2cBZYogkrcNs5waBAGdstbeU9zdqpw0gPwwSAI6VUxHyFlDpOcHUUBBIuYNs14aZAE5RVwyzPr3/0EAEY0TyfGNjBWQvwZ +CTSbehfAH29mrID8bET0+0EUkAd8WYDOmqJ3ecsG30yr9wqRfm6Y+a1BEFDEjHfHvWmY9ck6CygHvBVr8Xhtb4ZE5HZA3y8DvBNA1TjnrmXWf+sioMwZX5V/VHXMGGMMoKdDCxCRvRWBdzKzdHEO+EisilbPyopHYqp6S9UCAsz4iojI7hUDAtyXVQgIDd6KnOoaWNkbI6FaPSuZGyMArsi7MZoloB4zviI/Nhr3X95jltwTRQmoIfgisy5ai+me67OI7fE4nrqjrqfK1t0eby0FPRB6oGVlchL3rgnfrq19RKbVBdhV9IOSwJmfmJi4vi/4ThERitwyCxVAFqydshuCX5awhQ9KtmuIWd8IDZED/nXT77rvVVv6sHRKwjYi91poqP7Dr+Y6JJ1VSZIMA3wkPNy6bX+o8Bcm0sXMdwM8Fxo0A3xORPaWBp6uPXsmbxCRD0NDL0dOANhVCXy6iAjMcjbcrMt3RITKwdMVRdFo+y5yvkL4eWZ+zHt/ZVD4dEVRNGotpst+dZZZH8k86lqn2pIvT/eqrNfn2xuyqYPZ8mv7s8pfn/8Pybm4TIjanscAAAAASUVORK5CYII=") no-repeat center center;
  background-size: 64px 64px;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: .8;
  filter: alpha(opacity=80);
  transition: all 0.2s ease-out;
}

.youtube .play:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

/* Bottom right text */
.youtube_play_button {
  width: 20%;
  height: 20%;
  position: absolute;
  top: 40%;
  left: 40%;
  background-color: black;
  opacity: 1;
  color: white;
  background-color:red; 
}

.youtube_play_button:hover {
  background-color:black;
}

.youtube_video:hover .youtube_play_button{
  background-color:black;
}

.video {
  position: relative;
  display: inline-block;
  
  /* ie7 inline-block support */
  *display: inline;
  *zoom: 1;
}

#over {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #ff0000;
}

 
.divdiv {
}
 
.ulul {
  list-style-type: none;
  box-sizing: border-box;
  height: 100%;
}
 
.h3h3 {
  /*font: bold 20px/1.5 Helvetica, Verdana, sans-serif;*/
}
 
.lili .imgimg {
  float: left;
  margin: 0 15px 0 0;
}
 
.lili .pp {
  /*font: 200 12px/1.5 Georgia, Times New Roman, serif;*/
}
 
.lili {
  padding: 10px;
  overflow: auto;
}
 
.lili:hover {
  background: var(--accent-bg-color);
  cursor: pointer;
}

asider {
  display: block;
  position: relative;
}

asider h2 {
  letter-spacing: 2px;
  background: white;
  color: black;
  line-height: 70px;
  padding-left: 20px;
  text-align: right;
}

/* Class name via Modernizr */
.csstransforms .asider {
  border-right: 70px solid white;
}
.csstransforms .asider h2 {
  /* Abs positioning makes it not take up vert space */
  position: absolute;
  top: 0;
  right: 0;

  /* Border is the new background */
  background: none;

  /* Rotate from top left corner (not default) */
  transform-origin: 0 0;
  transform: rotate(90deg);
}

.rotated-text {
  text-align: right;
  position: relative;
  transform-origin: left bottom;
  transform: rotate(90deg);
}

aside {
  display: block;
  position: relative;
}

aside h2 {
  letter-spacing: 2px;
  background: white;
  color: black;
  line-height: 70px;
  padding-left: 10px;

}

/* Class name via Modernizr */
.csstransforms aside {
  /*border-left: 70px solid white;*/
}
.csstransforms aside h2 {
  /* Abs positioning makes it not take up vert space */
  position: absolute;
  top: 0;
  left: 0;

  /* Border is the new background */
  background: none;

  /* Rotate from top left corner (not default) */
  transform-origin: 0 0;
  /*transform: rotate(90deg);*/
}

.rotate {

  transform: rotate(-90deg);


  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

}

.big-box,
.mini-box {
    background-color: #10BCFF;
    color: white;
    text-align: center;
    margin: 2px;
    font-size: 1.5em;
    position:fixed;bottom:0;left:0;    
            background:lightgray;width:100%
}
.big-box {
    height: 65px;
    line-height: 65px;
    position:fixed;bottom:0;left:0;    
            background:lightgray;width:100%
}
.mini-box {
    height: 30px;
    line-height: 30px;
    position:fixed;bottom:0;left:0;    
            background:lightgray;width:100%
}

.glitch:hover{
  transition: 2s ease-out;
  transform: scale(1.005);
  filter: grayscale(0.1);
}

.glitch{
  transition: 1s ease-out;
  transform: scale(1);
  filter: grayscale(0.5);
}