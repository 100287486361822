.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

accordion article a {
	color: #dbdbdb;
	text-decoration: none;
	font-weight: 600;
}
accordion article a:hover, a:active {
	color: #ffdd44;
	font-weight: 600;
}
#container {
	margin: 0 auto;
	width: 75%;
	padding-bottom: 45px;
}
#accordion input {
	display: none;
}
#accordion label {
	background: #666;
	border-radius: 25px;
	cursor: pointer;
	display: block;
	margin-bottom: .125em;
	padding: 1em 1em;
	z-index: 20;
	font-weight: 300;
}
#accordion label:hover {
	background: #888;
}
#accordion input:checked + label {
	background: #999;
	border-radius: 25px 25px 0 0;
	color: #dbdbdb;
	margin-bottom: 0;
}
#accordion article {
	background: #444;
	height: 0px;
	overflow: hidden;
	z-index: 10;
	align-content: center;
}
#accordion article p {
	padding: 1em;
}
accordion article p a {
	color: #dbdbdb;
	text-decoration: none;
	font-weight: 600;
}
accordion article p a:hover, a:active {
	color: #ffdd44;
	font-weight: 600;
}

#accordion input:checked article {
}
#accordion input:checked ~ article {
	border-radius: 0 0 25px 25px;
	/*border-bottom-right-radius: .5em;*/
	height: auto;
	line-height: 1.6;
	margin-bottom: .125em;
	padding-top: 20;
	padding-bottom: 20px;
	align-content: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  font-style: normal; font-feature-settings: normal; font-variant: normal; font-weight: 400; 
  font-size: 1.17em !important;
    /* IE */
    /*filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);*/
    /* Chrome, Safari */
    /*-webkit-filter: grayscale(0.8);*/
    /* Firefox */
    /*filter: grayscale(0.8);*/
}



h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .display-1, .display-2,.display-3,.display-4  {
  margin-bottom: 0.5rem;
  font-family: "Helvetica Neue";
  font-weight: 350;
  line-height: 1.2;
  color: inherit;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
h1 { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-size: 24px; font-style: normal; font-variant: normal; font-weight: 100; line-height: 26.4px; } 
h3 { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-size: 14px; font-style: normal; font-variant: normal; font-weight: 100; line-height: 15.4px; } 
p { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; ; font-style: normal; font-variant: normal; font-weight: 100; line-height: 20px; } 
blockquote { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-style: normal; font-variant: normal; font-weight: 100; line-height: 25px; } 
pre { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-style: normal; font-variant: normal; font-weight: 100; line-height: 18.5714px; }
a { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-style: normal; font-variant: normal; font-weight: 100; line-height: 18.5714px; }
body { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-style: normal; font-variant: normal; font-weight: 100; line-height: 18.5714px; }
*/

.main {
    /*background: #2d2d2d;*/
    border-color: #E7E7E7;
    color: #f1f1f1;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    margin: 0 0 0 0;
    right: 0px;
    left: 0px;
    top: 0;
    background: black;
}

/* Container holding the image and the text */
.container-main {
  position: relative;
  /*margin-bottom: 50px;*/
  /*transition: 0.4s cubic-bezier(1, -1, 0, 2);
  clip-path: circle(600px at center);*/
}

/* Bottom right text */
.text-block-main {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: black;
  opacity: 1;
  color: white;
  background:-webkit-gradient(linear,left top, left bottom,from(rgba(0, 0, 0, 1)) ,color-stop(30%, rgba(0, 0, 0, 0.5)));
  background:linear-gradient(to bottom,rgba(0, 0, 0, 1) ,rgba(0, 0, 0, 0.5) 30%);
  background-color:transparent; /*this your primary color*/
}




/* Bottom right text */
.text-block-main-right {
  width: 100%;
  height: 10%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0);
  opacity: 1;
  color: white;
  text-align: middle;
  vertical-align: middle;
}

.text-block-main-bottom {
  width: 75%;
  height: 12.5%;
  position: absolute;
  right: 12.5%;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 1);
  opacity: 1;
  color: white;
  text-align: middle;
  vertical-align: middle;
}

.display-44 {
  color: white;
}

.manual_link {
  color: var(--accent-bg-color);
}


.store-button {
  border-color: white;
  color: white;
  background-color: black;
  opacity: 1;

}

.store-button:hover {
  -webkit-transition: 1s ease-out;
  transition: 1s ease-out;
  background-color: var(--accent-bg-color);
  color: white;
  opacity: 1;

}

.list-group-item {
  background: black;;
}

.desc-text-left {
    /*height: 100%;*/
    width: 30%;
    text-align: left;
    float: left;
}


.desc-image-right {
  /*height: 100%;*/
   width: 70%;
    /*text-align: right;
    float: left;*/
    opacity: 0.9;
    -webkit-transition: .5s ease;
    transition: .5s ease;
}

.desc-image-right:hover {
  opacity: 1;
}

.desc-image-left {
    width: 70%;
    height: 100%;
    text-align: left;
    float: left;
    overflow: hidden;
    opacity: 0.9;
    -webkit-transition: .5s ease;
    transition: .5s ease;
}

.desc-image-left:hover {
  opacity: 1;
}


.desc-text-right {
    width: 30%;
    height: 100%;
    float: left;
}

.footer-heading {
  color: white;
}

.copyright-footer {
  color: white;
}

.bg-dark {
  background: black;
}

.ul_text_left {
  text-align: left;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.asdasd {
    width: 100%;
}

.desc_box_margin {
  /*margin-top: 25px;
  margin-bottom: 25px;*/
}

.footer_styles {
  background-color: var(--navbar-bg-color);
}

.footer_margin {
  margin-top: 25px;
}


.product_row {
    background-color: #C0C0C0;
}

.product_row_box {
  vertical-align: middle;
  text-decoration: none;
  background-color: var(--secodary-bg-color);
  color: white;
  box-shadow: -2px -2px 5px 4px #B0B0B0
}

.product_detail_box{
  -webkit-transition: 1s ease-out;
  transition: 1s ease-out;
  /*background-color: var(--accent-bg-color);*/
}

.product_detail_box:hover{
  -webkit-transition: 1s ease-out;
  transition: 1s ease-out;
  background-color: white;
  -webkit-transform: scale(1.005);
          transform: scale(1.005);
}

.image_bottom {
  /*position: absolute;
  bottom: 0px;
  width: 100;
  text-align: center;
  width: 100%;
  background-color: var(--secondary-accent-bg-color);*/
  vertical-align:middle;
}

.product_row_box:hover{
  -webkit-transition: 1s ease-out;
  transition: 1s ease-out;
  background-color: white;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  
}

.product_row_box{
  -webkit-transition: 1s ease-out;
  transition: 1s ease-out;
  -webkit-transform: scale(1);
          transform: scale(1);
}


.inner-product-box:hover {
  -webkit-transition: 1s ease-out;
  transition: 1s ease-out;
  /*transform: scale(1.15);*/
}

.inner-product-box {
  -webkit-transition: 1s ease-out;
  transition: 1s ease-out;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.product_row_box:hover a h4{
  -webkit-transition: 2s ease-out;
  transition: 2s ease-out;
  color: black;
}

.product_row_box:hover a .product_box_desc{
  -webkit-transition: 2s ease-out;
  transition: 2s ease-out;
  color: black;
}



.main_bar_margin {
  margin-top: 56px;
}

.sub_bar_margin {
  margin-top: 40px;
}

.font_color_main_background {
  color: var(--main-bg-color);
}

a {
  color: #ffffff ;
}
a:visited {
  color: #ffffff ;
}
a:hover, a:active, a:focus {
  color: #ffffff ;
}
a:hover {
  color: #ffffff ;
  text-decoration: none;
}


.flexy-header { height: 10vh; }
.hero-content { -webkit-transition: all 3s ease, opacity 0.5s ease; transition: all 3s ease, opacity 0.5s ease; opacity: 1; }
.flexy-header .hero-content { opacity: 0; height: 0; -webkit-transition: all 3s ease, opacity 0.5s ease; transition: all 3s ease, opacity 0.5s ease; }

.youtube {
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  overflow: hidden;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  cursor: pointer;
}

.youtube .play {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAERklEQVR4nOWbTWhcVRTHb1IJVoxGtNCNdal2JYJReC6GWuO83PM/59yUS3FRFARdFlwYP1CfiojQWt36sRCUurRIdVFXIn41lAoVdRGrG1M01YpKrWjiYmaSl8ybZJL3cd+YA//NLObd3++eO8x79z5jSq5Gw+8kov0AP8vMR5l1BtBZQM4B8ks75wCdZdYZZj5qLZ4hov2Nht9Z9vhKKSIaB/gI4M4w62KeAO6Mte4lYOq20FxrlqqOibhHmeWbvNC9ZfDX1mLae391aN6limO/gwgvAPJbWeAZuSDingdwXTBw7/0IsyaA/Fkh+KqOkD+YNfHej1QKD+y7iVlOhgLvFqFfNJvNGyuBJ+KDAF8MDd0tgS8y64OlgSdJMsysL4cG7SOHkyQZLhTee7+d2R2rAVy/S+Jd7/32ouBHAP4gNNRGQyTHc/84NhqNywZp5rvjjnnvt21aABFeCQ+RLwAf2hQ8s7sv9OCLk6AHNgQvIrvbfzKCD76g/O6cu7lf/iER/aQGgy448pExZmhdegAPhR9sObFWH1gT3lp7DaA/5bkIgJhZPgsNmz02novj+KqeApj1ubwXWe4kdyeznAgNvTpE/HQmvKqOMeuFogTUVQSRno+iaLRLAJF7uIgL9O4ubgL8aWgB7S44mNX+35YpICUiAvS9sBLkq1WzT+NFffl6AuoiApi6NT37h6sWkBIRZGkQ8YtLgyji6e1mBYTqCEBPG2Naz+0BWQgtoGoRgCzEsd9hAN1X5BfnFZASUfrSAFQNsyZ1FJASUVpHiLinDJG8U2cBZYogkrcNs5waBAGdstbeU9zdqpw0gPwwSAI6VUxHyFlDpOcHUUBBIuYNs14aZAE5RVwyzPr3/0EAEY0TyfGNjBWQvwZ +CTSbehfAH29mrID8bET0+0EUkAd8WYDOmqJ3ecsG30yr9wqRfm6Y+a1BEFDEjHfHvWmY9ck6CygHvBVr8Xhtb4ZE5HZA3y8DvBNA1TjnrmXWf+sioMwZX5V/VHXMGGMMoKdDCxCRvRWBdzKzdHEO+EisilbPyopHYqp6S9UCAsz4iojI7hUDAtyXVQgIDd6KnOoaWNkbI6FaPSuZGyMArsi7MZoloB4zviI/Nhr3X95jltwTRQmoIfgisy5ai+me67OI7fE4nrqjrqfK1t0eby0FPRB6oGVlchL3rgnfrq19RKbVBdhV9IOSwJmfmJi4vi/4ThERitwyCxVAFqydshuCX5awhQ9KtmuIWd8IDZED/nXT77rvVVv6sHRKwjYi91poqP7Dr+Y6JJ1VSZIMA3wkPNy6bX+o8Bcm0sXMdwM8Fxo0A3xORPaWBp6uPXsmbxCRD0NDL0dOANhVCXy6iAjMcjbcrMt3RITKwdMVRdFo+y5yvkL4eWZ+zHt/ZVD4dEVRNGotpst+dZZZH8k86lqn2pIvT/eqrNfn2xuyqYPZ8mv7s8pfn/8Pybm4TIjanscAAAAASUVORK5CYII=") no-repeat center center;
  background-size: 64px 64px;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.youtube .play:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

/* Bottom right text */
.youtube_play_button {
  width: 20%;
  height: 20%;
  position: absolute;
  top: 40%;
  left: 40%;
  background-color: black;
  opacity: 1;
  color: white;
  background-color:red; 
}

.youtube_play_button:hover {
  background-color:black;
}

.youtube_video:hover .youtube_play_button{
  background-color:black;
}

.video {
  position: relative;
  display: inline-block;
  
  /* ie7 inline-block support */
  *display: inline;
  *zoom: 1;
}

#over {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #ff0000;
}

 
.divdiv {
}
 
.ulul {
  list-style-type: none;
  box-sizing: border-box;
  height: 100%;
}
 
.h3h3 {
  /*font: bold 20px/1.5 Helvetica, Verdana, sans-serif;*/
}
 
.lili .imgimg {
  float: left;
  margin: 0 15px 0 0;
}
 
.lili .pp {
  /*font: 200 12px/1.5 Georgia, Times New Roman, serif;*/
}
 
.lili {
  padding: 10px;
  overflow: auto;
}
 
.lili:hover {
  background: var(--accent-bg-color);
  cursor: pointer;
}

asider {
  display: block;
  position: relative;
}

asider h2 {
  letter-spacing: 2px;
  background: white;
  color: black;
  line-height: 70px;
  padding-left: 20px;
  text-align: right;
}

/* Class name via Modernizr */
.csstransforms .asider {
  border-right: 70px solid white;
}
.csstransforms .asider h2 {
  /* Abs positioning makes it not take up vert space */
  position: absolute;
  top: 0;
  right: 0;

  /* Border is the new background */
  background: none;

  /* Rotate from top left corner (not default) */
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.rotated-text {
  text-align: right;
  position: relative;
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

aside {
  display: block;
  position: relative;
}

aside h2 {
  letter-spacing: 2px;
  background: white;
  color: black;
  line-height: 70px;
  padding-left: 10px;

}

/* Class name via Modernizr */
.csstransforms aside {
  /*border-left: 70px solid white;*/
}
.csstransforms aside h2 {
  /* Abs positioning makes it not take up vert space */
  position: absolute;
  top: 0;
  left: 0;

  /* Border is the new background */
  background: none;

  /* Rotate from top left corner (not default) */
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  /*transform: rotate(90deg);*/
}

.rotate {

  transform: rotate(-90deg);


  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

}

.big-box,
.mini-box {
    background-color: #10BCFF;
    color: white;
    text-align: center;
    margin: 2px;
    font-size: 1.5em;
    position:fixed;bottom:0;left:0;    
            background:lightgray;width:100%
}
.big-box {
    height: 65px;
    line-height: 65px;
    position:fixed;bottom:0;left:0;    
            background:lightgray;width:100%
}
.mini-box {
    height: 30px;
    line-height: 30px;
    position:fixed;bottom:0;left:0;    
            background:lightgray;width:100%
}

.glitch:hover{
  -webkit-transition: 2s ease-out;
  transition: 2s ease-out;
  -webkit-transform: scale(1.005);
          transform: scale(1.005);
  -webkit-filter: grayscale(0.1);
          filter: grayscale(0.1);
}

.glitch{
  -webkit-transition: 1s ease-out;
  transition: 1s ease-out;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-filter: grayscale(0.5);
          filter: grayscale(0.5);
}

/* navbar */
.fixed-top-2 {
    height: 45px;
    border-bottom: 0px solid black;
}

.bottom_border_class {
  border-bottom: 1px solid rgba(255, 255, 255, 0.01);
  /*z-index: 8888;
  top: 50px;
  margin-top: -50px;*/
}


/* navbar */
.navbar-dark {
    background-color: var(--navbar-bg-color);
    /*border-color: var(--accent-bg-color);*/
}
/* navbar */
.navbar-black {
    /*transition: 1.1s ease-out;*/
    background-color: rgba(0, 0, 0, 0.9);
}
/* navbar */
.navbar-transparent {
    -webkit-transition: 1.1s ease-out;
    transition: 1.1s ease-out;
    background-color: rgba(0, 0, 0, 0);
}
/* Title */
.navbar-dark .navbar-brand {
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    -webkit-transition: 0.5s ease-out;
    transition: 0.5s ease-out;
    background-color: var(--accent-bg-color);
}

.navbar-dark .dropdown-toggle:hover,
.navbar-dark .dropdown-toggle:focus {
    -webkit-transition: 0.5s ease-out;
    transition: 0.5s ease-out;
    background-color: var(--accent-bg-color);
}


.navbar-dark .nav-link:hover,
.navbar-dark .nav-link:focus {
    color: var(--accent-bg-color);
}

.nav > li > a:hover, .nav > li > a:focus {
    color: black;
    -webkit-transition: 0.5s ease-out;
    transition: 0.5s ease-out;
    /*background-color: var(--accent-bg-color);*/
    color: black;
}

.id-item > li > a:hover, .id-item > li > a:focus {
    -webkit-transition: 0.5s ease-out;
    transition: 0.5s ease-out;
    background-color: var(--accent-bg-color);
    color: black;
}



.id-item > li > nav-link:hover , .id-item > li > nav-link:hover {
    color: black;
}

.hover_color_red {
}
/* Link */
.navbar-dark .navbar-nav > li > a {
}
.navbar-dark .navbar-nav > li > a:hover,
.navbar-dark .navbar-nav > li > a:focus {

}


.navbar-dark .navbar-nav > .active > a,
.navbar-dark .navbar-nav > .active > a:hover,
.navbar-dark .navbar-nav > .active > a:focus {
}
.navbar-dark .navbar-nav > .open > a,
.navbar-dark .navbar-nav > .open > a:hover,
.navbar-dark .navbar-nav > .open > a:focus {
    
}
/* Caret */
.navbar-dark .navbar-nav > .dropdown > a .caret {

}
.navbar-dark .navbar-nav > .dropdown > a:hover .caret,
.navbar-dark .navbar-nav > .dropdown > a:focus .caret {
}
.navbar-dark .navbar-nav > .open > a .caret,
.navbar-dark .navbar-nav > .open > a:hover .caret,
.navbar-dark .navbar-nav > .open > a:focus .caret {
 
}
/* Mobile version */
.navbar-dark .navbar-toggle {
}
.navbar-dark .navbar-toggle:hover,
.navbar-dark .navbar-toggle:focus {
}
.navbar-dark .navbar-toggle .icon-bar {
}
@media (max-width: 767px) {
    .navbar-dark .navbar-nav .open .dropdown-menu > li > a {
    }
    .navbar-dark .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-dark .navbar-nav .open .dropdown-menu > li > a:focus {
    }
}

.navbar-fixed-left {
    width: 140px;
    position: fixed;
    border-radius: 0;
    height: 100%;
  }
  
  .navbar-fixed-left .navbar-nav > li {
    float: none;  /* Cancel default li float: left */
    width: 139px;
  }
  
  .navbar-fixed-left + .container {
    padding-left: 160px;
  }
  
  /* On using dropdown menu (To right shift popuped) */
  .navbar-fixed-left .navbar-nav > li > .dropdown-menu {
    margin-top: -50px;
    margin-left: 140px;
  }


  /* Sidebar Styles */

#sidebar {
  z-index: 1000;
  position: fixed;
  right: 250px;
  width: 200px;
  margin-right: -250px;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#sidebar header {
  background-color: #263238;
  font-size: 20px;
  line-height: 52px;
  text-align: center;
}

#sidebar header a {
  color: #fff;
  display: block;
  text-decoration: none;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

#sidebar header a:hover {
  color: #fff;
}

#sidebar .nav{
  
}

#sidebar .nav a{
  background: none;
  border-bottom: 1px solid #455A64;
  color: #CFD8DC;
  font-size: 14px;
  padding: 16px 24px;
}

#sidebar .nav a:hover{
  background: none;
  color: #ECEFF1;
}

#sidebar .nav a i{
  margin-right: 16px;
}

:root {
    --main-bg-color: rgb(20, 14, 14);
    --secodary-bg-color: rgb(27, 23, 23);
    --navbar-bg-color: transparent;

    --sixth-accent-bg-color: rgb(162, 3, 241);
    --seventh-accent-bg-color: rgb(238, 198, 129);
    --eight-accent-bg-color: rgb(106, 243, 174);
    --touchable-bg-color: rgb(151, 58, 62);


    --accent-bg-color: #FF652F;
    --secondary-accent-bg-color: #FFE400;
    --third-accent-bg-color: #14A76C;
    --fourth-accent-bg-color: rgba(132, 153, 0);
    --fifth-accent-bg-color: rgba(205, 74, 46); 

    /*--accent-bg-color: rgba(230, 240, 220); 
    --secondary-accent-bg-color: rgba(180, 200, 201); 
    --third-accent-bg-color: rgba(140, 155, 128); 
    --fourth-accent-bg-color: rgba(110, 105, 100);
    --fifth-accent-bg-color: rgba(80, 70, 75); */


    --main-bg-color: rgb(10, 9, 9);
    --secodary-bg-color: rgb(44, 3, 3);
    --navbar-bg-color: transparent;


    /*--accent-bg-color: rgba(107, 112, 196);
    --secondary-accent-bg-color: rgba(196, 140, 209);
    --third-accent-bg-color: rgba(40, 160, 153);
    --fourth-accent-bg-color: rgba(132w, 153, 0);
    --fifth-accent-bg-color: rgba(205, 74, 46);*/
    /* --sixth-accent-bg-color: rgba(219, 51, 46, 0.5);
    --seventh-accent-bg-color: rgba(212, 51, 135, 0.5);
    --eight-accent-bg-color: rgba(212, 51, 135 , 0.5);
    --touchable-bg-color: rgba(245,74,83, 0.5);
    --accent-bg-color: rgba(148, 0, 0211 , 0.5);
    --secondary-accent-bg-color: rgba(75, 0, 130 , 0.5);
    --third-accent-bg-color: rgba(0,0,255 0.5);
    --fourth-accent-bg-color: rgba(255,255,0 , 0.5);
    --fifth-accent-bg-color: rgba(255,127,0. 0.5); */


    /* --accent-bg-color: #581845;
    --secondary-accent-bg-color: #900C3F;
    --third-accent-bg-color: #C70039;
    --fourth-accent-bg-color: #FF5733;
    --fifth-accent-bg-color: #FFC30F; */

/*
     --accent-bg-color: #36C5F0;
    --secondary-accent-bg-color: #E01E5A;
    --third-accent-bg-color: #ECB33E;
    --fourth-accent-bg-color: #2EB67D;
    --fifth-accent-bg-color: rgb(219, 212, 215);  
    --sixth-accent-bg-color: rgba(219, 51, 46);
    --seventh-accent-bg-color: rgba(212, 51, 135);
    --eight-accent-bg-color: rgba(212, 51, 135);*/


    --small-margin: 25px;
    --big-margin: 50px;
  }
  
  .background_color_accent {
    background-color: #FF652F;
    background-color: var(--accent-bg-color);
  }

  .background_color_accent_sec {
    background-color: #FFE400;
    background-color: var(--secondary-accent-bg-color);
  }

  .background_color_accent_third {
    background-color: #14A76C;
    background-color: var(--third-accent-bg-color);
  }

  .background_color_accent_fourth {
    background-color: rgba(132, 153, 0);
    background-color: var(--fourth-accent-bg-color);
  }

  .background_color_accent_fifth {
    background-color: rgba(205, 74, 46);
    background-color: var(--fifth-accent-bg-color);
  }

  
.padding-left-20 {
  padding-left: 10px;
}
  .uniform_small_margin {
    margin: 25px;
    margin: var(--small-margin);
  }
  
  .uniform_big_margin {
    margin: 50px;
    margin: var(--big-margin);
  }

  .bottom_big_margin {
    margin-bottom: 50px;
    margin-bottom: var(--big-margin);
  }

  .top_big_margin {
    margin-top: 50px;
    margin-top: var(--big-margin);
  }

  .left_big_margin {
    margin-left: 50px;
    margin-left: var(--big-margin);
  }


  .bottom_small_margin {
    margin-bottom: 25px;
    margin-bottom: var(--small-margin);
  }

  .text_color_accent {
    color: #FF652F;
    color: var(--accent-bg-color);
  }

  .text_color_black {
    color: black;
  }

  .text_color_accent:hover {
    color: #FF652F;
    color: var(--accent-bg-color);
  }

  .text_color_main:hover {
    color: rgb(10, 9, 9);
    color: var(--main-bg-color);
  }

  .text_decoration {
      text-decoration: underline;
      -webkit-text-decoration-color: #FF652F;
              text-decoration-color: #FF652F;
      -webkit-text-decoration-color: var(--accent-bg-color);
              text-decoration-color: var(--accent-bg-color);
  }

  .accent_bottom_border {
    border-bottom: 1px solid #FF652F;
    border-bottom: 1px solid var(--accent-bg-color);
}

.accent_right_border {
  border-right: 1x solid #FF652F;
  border-right: 1x solid var(--accent-bg-color);
}

.accent_left_border {
  border-left: 1px solid #FF652F;
  border-left: 1px solid var(--accent-bg-color);
}

.accent_top_border {
    border-top: 1px solid #FF652F;
    border-top: 1px solid var(--accent-bg-color);
}


.img_color_accent {
        -webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
        filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
  }

  .img_color_black {
    -webkit-filter: invert(100%) grayscale(100%) brightness(70%) contrast(4);
    filter: invert(100%) grayscale(100%) brightness(70%) contrast(4);
}

.fill { 
  min-height: 100%;
  height: 100%;
}

section:nth-child(even) {
  background-color: #1c1c1c;
  color: #dbdbdb;
  }
  
  /* img { */
    /* verical-align: middle;
    :hover {
      cursor: pointer;
      .red {
        -webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
        filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
      }
      .blue {
        -webkit-filter: grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.8);
        filter: grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.8);
      }
      .green {
        -webkit-filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
        filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
      }
      .yellow {
        -webkit-filter: grayscale(100%) brightness(120%) sepia(90%) hue-rotate(5deg) saturate(500%) contrast(0.7);
        filter: grayscale(100%) brightness(120%) sepia(90%) hue-rotate(5deg) saturate(500%) contrast(0.7);
      }
      .grey {
        -webkit-filter: grayscale(100%) brightness(110%) contrast(0.9);
        filter: grayscale(100%) brightness(110%) contrast(0.9);
      }
      .black {
        -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
        filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
      }
    }
  } */
